import { add, differenceInMinutes, formatDuration, intervalToDuration, parseISO } from 'date-fns';
import { nl } from 'date-fns/locale';
import { ReservationModel } from 'hooks/api/reservation/models/reservation';
import { RepeatInterval } from 'utils/constants/repeatIntervalConstants';
import { formatInUtc, getCurrentDate, getEndOfDay, getFirstSelectableTimeOfDay, getStartOfDay } from 'utils/dateHelper';
import { DateTimeFormat } from 'utils/constants';
import { DatedRentalBlockModel, RentalBlockType } from 'hooks/api/rentalBlock/models/rentalBlock';
import { orderBy } from 'lodash';
import { PartlyDefinedPeriodModel, PeriodModel } from 'hooks/api/global/models/dateTimePeriod';

export const datesOnSameDay = (d1: Date, d2: Date) =>
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();

export const formatReservationOccurrence = (reservation: ReservationModel): string => {
    let output = '';
    const startTime = parseISO(reservation.reservationTime.from);
    const endTime = parseISO(reservation.reservationTime.to);

    if (reservation.isOneTimeRental) {
        output = 'Eenmalig op ';
        output += formatInUtc(startTime, DateTimeFormat.FullDateWithYear, { locale: nl });
    } else {
        const day = formatInUtc(startTime, DateTimeFormat.Day, { locale: nl });

        switch (reservation.repeatInterval) {
            case RepeatInterval.EveryWeek:
                output = 'Iedere';
                break;
            case RepeatInterval.Every2Weeks:
                output = 'Om de week op';
                break;
            case RepeatInterval.Every3Weeks:
                output = 'Om de 3 weken op';
                break;
            case RepeatInterval.EveryMonth:
                output = 'Elke 4 weken op';
                break;
        }

        output += ` ${day}`;
    }

    const duration = intervalToDuration({ start: startTime, end: endTime });
    output += ` ${formatInUtc(startTime, DateTimeFormat.TimeWithOutSeconds, { locale: nl })} - `;

    if (!datesOnSameDay(startTime, endTime)) {
        output += ` ${formatInUtc(endTime, DateTimeFormat.Day, { locale: nl })}`;
    }

    output += ` ${formatInUtc(endTime, DateTimeFormat.TimeWithOutSeconds, { locale: nl })} (${formatDuration(duration, { locale: nl })})`;

    return output;
};

export interface SlotModel {
    start: Date;
    duration: number; // In Minutes
}

export const getTimeStopPeriod = (
    selectedDate: PartlyDefinedPeriodModel | null,
    isEndTimeSelected: boolean
) => {
    const startDate = selectedDate?.from != null
            ? selectedDate.from
            : getCurrentDate().date;
    const startTime = getFirstSelectableTimeOfDay(startDate);

    const endTime = selectedDate?.to != null && isEndTimeSelected
        ? selectedDate.to > startDate && selectedDate.to.getTime() === getStartOfDay(selectedDate.to).getTime()
            ? selectedDate.to
            : getEndOfDay(selectedDate.to)
        : getEndOfDay(startDate);

    const period : PeriodModel = {
        from: startTime,
        to: endTime
    };

    return period;
};

export const getTimeStops = (
    start: Date,
    end: Date,
    slot: number,
    rentableRoomBlocks: DatedRentalBlockModel[],
    closedTimes: PeriodModel[]
) => {
    const stops: SlotModel[] = [];

    if (end < start) {
        return stops;
    }

    let blocks = rentableRoomBlocks
        .filter(b => b.type === RentalBlockType.Block)
        .map((b) => ({
            from: parseISO(b.from),
            to: parseISO(b.to)
        }));

    // Add closed times to the blocks
    blocks.push(...closedTimes);

    blocks = orderBy(blocks, 'from');

    let currentBlock = null;

    const delta = 60000;
    // Keep creating blocks until the difference between the start and end time is under a minute
    while (end.getTime() - start.getTime() >= delta) {

        // Check if current block is still "active"
        if (currentBlock != null && start >= currentBlock.to) {
            currentBlock = null;
        }

        // Check for new blocks
        if (blocks.length > 0 && start >= blocks[0].from) {
            currentBlock = blocks.shift();
        }

        // Calculate end of time block
        let end;

        if (currentBlock != null) {
            end = currentBlock.to;
        } else {
            end = add(start, { minutes: slot });
        }

        // Check if next start is in a new block
        if (blocks.length > 0 && end >= blocks[0].from) {
            end = blocks[0].from;
        }

        stops.push({
            start,
            duration: differenceInMinutes(end, start)
        });

        start = end;
    }

    return stops;
};
