export enum PriceDefinitionUnit {
    Minute = 0,
    Hour = 1,
    Day = 2,
    Week = 3,
    Month = 4,
    Reservation = 5,
}

export enum PriceSpecificationUnit {
    Minute = 0,
    Hour = 1,
    Day = 2,
    Week = 3,
    Month = 4,
    Unit = 5,
}

export const PriceUnitValues = [
    { 
        value: PriceDefinitionUnit.Minute, 
        label: 'Per minuut',
        shortLabel: 'Per minuut',
        option: true,
        object: true
    },
    { 
        value: PriceDefinitionUnit.Hour,
        label: 'Per uur (gefactureerd per gehele minuut)',
        shortLabel: 'Per uur',
        option: true,
        object: true
    },
    {
        value: PriceDefinitionUnit.Day,
        label: 'Per dag (gefactureerd per gehele minuut)',
        shortLabel: 'Per dag',
        option: true,
        object: true
    },
    {
        value: PriceDefinitionUnit.Week,
        label: 'Per week (gefactureerd per gehele minuut)',
        shortLabel: 'Per week',
        option: true,
        object: true
    },
    { 
        value: PriceDefinitionUnit.Month,
        label: 'Per vier weken (gefactureerd per hele maanden)',
        shortLabel: 'Per vier weken',
        option: true,
        object: false
    },
    {
        value: PriceDefinitionUnit.Reservation,
        label: 'Per reservering',
        shortLabel: 'Per reservering',
        option: true,
        object: false
    }
];
